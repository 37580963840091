<template>
  <v-container fluid>
    <v-row v-for='service in customerServices' :key='service.name'>
      <v-row v-if='list.filter((l) => l.serviceName === service.name).length > 0' no-gutters>
        <v-card-title class='px-4 py-2 color-secondary'> {{ service.name }} </v-card-title>
        <LicenseManagerItems :list='list.filter((l) => l.serviceName === service.name)' @reload='getList()' />
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  import { mapGetters } from 'vuex';
  import { format } from 'date-fns';

  export default {
    name: 'LicenseManager',
    components: {
      LicenseManagerItems: () => import('../../components/LicenseManager/LicenseManagerItems'),
    },
    data: function () {
      return {
        list: [],
        customerServices: [],
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    mounted: function () {
      setTimeout(() => {
        this.$nextTick(() => {
          this.getCustomerAvailableServices();
          this.getList();
        });
      }, 500);
    },
    methods: {
      getCustomerAvailableServices: async function () {
        try {
          const { data } = await axios({
            url: `/customer/${this.getUser.account.customerId}/available-services`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.customerServices = data && data.services;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getList: async function () {
        try {
          const { data } = await axios({
            url: `/customer-application-info/customer/${this.getUser.account.customerId}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      revokeLicense: async function (app) {
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: `Deseja realmente revogar a licença da maquina ${app.hwHostname} esta licença?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.value) {
            await axios({
              url: `/customer-application-info/${this.getUser.account.customerId}/${app.id}`,
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'DELETE',
              responseType: 'json',
              withCredentials: true,
            });
            this.getList();
          }
        });
      },
      formatDate: function (date, formatString) {
        return format(new Date(date), formatString);
      },
    },
  };
</script>
